import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import "./App.css";
import logo from "./logo.png"; // Logo için
import refinery from "./refinery.png"; // İkinci uygulama ikonu
import modipic from "./drobify.png"; // Üçüncü uygulama ikonu
import diress from "./diress.png"; // Dördüncü uygulama ikonu
import separai from "./separai.png"; // Beşinci uygulama ikonu
import minipi from "./minipi.png"; // Altıncı uygulama ikonu
import coupleshot from "./coupleshot.png"; // CoupleShot uygulama ikonu
import producter from "./producter.png"; // Producter uygulama ikonu
import simu from "./simu.jpg"; // Simu uygulama ikonu
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";
import Contact from "./Contact";

// Minipi redirect component
function MinipiRedirect() {
  const [error, setError] = useState(false);
  const [countryCode, setCountryCode] = useState("us"); // Default to US store
  const [redirectMessage, setRedirectMessage] = useState(
    "Redirecting to App Store..."
  ); // Default to English

  // Localized redirect messages
  const redirectMessages = {
    tr: "App Store'a yönlendiriliyorsunuz",
    us: "Redirecting to App Store",
    uk: "Redirecting to App Store",
    de: "Weiterleitung zum App Store",
    fr: "Redirection vers l'App Store",
    es: "Redireccionando a la App Store",
    it: "Reindirizzamento all'App Store",
    jp: "App Storeにリダイレクトしています",
    kr: "앱 스토어로 리디렉션 중",
    cn: "正在重定向到App Store",
    ru: "Перенаправление в App Store",
    br: "Redirecionando para a App Store",
    // Add more languages as needed
  };

  // Complete localized error messages with click here text integrated
  const completeErrorMessages = {
    tr: {
      before: "Yönlendirme hatası. App Store'a gitmek için lütfen ",
      link: "buraya tıklayın",
      after: ".",
    },
    us: {
      before: "Error redirecting. Please ",
      link: "click here",
      after: " to go to the App Store.",
    },
    uk: {
      before: "Error redirecting. Please ",
      link: "click here",
      after: " to go to the App Store.",
    },
    de: {
      before: "Fehler bei der Weiterleitung. Bitte ",
      link: "klicken Sie hier",
      after: ", um zum App Store zu gelangen.",
    },
    fr: {
      before: "Erreur de redirection. S'il vous plaît ",
      link: "cliquez ici",
      after: " pour accéder à l'App Store.",
    },
    es: {
      before: "Error al redirigir. Por favor ",
      link: "haga clic aquí",
      after: " para ir a la App Store.",
    },
    it: {
      before: "Errore di reindirizzamento. Per favore ",
      link: "clicca qui",
      after: " per andare all'App Store.",
    },
    jp: {
      before: "リダイレクトエラー。App Storeに移動するには",
      link: "ここをクリック",
      after: "してください。",
    },
    kr: {
      before: "리디렉션 오류. 앱 스토어로 이동하려면 ",
      link: "여기를 클릭하세요",
      after: ".",
    },
    cn: {
      before: "重定向错误。请",
      link: "点击这里",
      after: "前往App Store。",
    },
    ru: {
      before: "Ошибка перенаправления. Пожалуйста, ",
      link: "нажмите здесь",
      after: ", чтобы перейти в App Store.",
    },
    br: {
      before: "Erro ao redirecionar. Por favor ",
      link: "clique aqui",
      after: " para ir para a App Store.",
    },
    // Add more languages as needed
  };

  useEffect(() => {
    // IMPORTANT: For this specific site, we want to prioritize Turkish users
    // Force redirect to Turkish store for certain conditions
    const forceRedirectToTurkishStore = () => {
      // Check if hostname contains .tr or is a known Turkish domain
      if (
        window.location.hostname.includes(".tr") ||
        window.location.hostname === "monailisa.com" ||
        window.location.hostname === "www.monailisa.com"
      ) {
        return true;
      }

      // Check if browser language is Turkish
      const userLanguage = navigator.language || navigator.userLanguage || "";
      if (userLanguage.startsWith("tr")) {
        return true;
      }

      return false;
    };

    // If we should force redirect to Turkish store, do it immediately
    if (forceRedirectToTurkishStore()) {
      console.log("Turkish user detected, redirecting to Turkish App Store");
      setCountryCode("tr");
      setRedirectMessage(redirectMessages["tr"] || redirectMessages["us"]);
      redirectToStore("tr");
      return;
    }

    try {
      // First try to get country from IP address
      fetch("https://ipapi.co/json/")
        .then((response) => response.json())
        .then((data) => {
          let detectedCountryCode = data.country_code?.toLowerCase() || "us";
          console.log("Country detected from IP:", detectedCountryCode);

          // Force Turkish store for Turkish IP addresses
          if (detectedCountryCode === "tr") {
            console.log(
              "Turkish IP detected, redirecting to Turkish App Store"
            );
            setCountryCode("tr");
            setRedirectMessage(
              redirectMessages["tr"] || redirectMessages["us"]
            );
            redirectToStore("tr");
            return;
          }

          // If we couldn't get a valid country code from IP, fall back to browser language
          if (!detectedCountryCode || detectedCountryCode.length !== 2) {
            // Get user's language and country code from browser
            const userLanguage =
              navigator.language || navigator.userLanguage || "en-US";
            console.log("Detected user language:", userLanguage);

            // Extract country code with better detection
            detectedCountryCode = "us"; // Default fallback

            // Special handling for Turkish users
            if (userLanguage.startsWith("tr")) {
              detectedCountryCode = "tr";
            } else if (userLanguage.includes("-")) {
              detectedCountryCode = userLanguage.split("-")[1].toLowerCase();
            } else if (userLanguage.includes("_")) {
              detectedCountryCode = userLanguage.split("_")[1].toLowerCase();
            }

            // Validate country code (must be 2 characters)
            if (detectedCountryCode.length !== 2) {
              detectedCountryCode = "us";
            }
          }

          // Map country codes to language codes for the redirect message
          let languageCode = detectedCountryCode;

          // Special mappings for some countries
          if (
            detectedCountryCode === "us" ||
            detectedCountryCode === "gb" ||
            detectedCountryCode === "au" ||
            detectedCountryCode === "ca"
          ) {
            languageCode = "us"; // English
          } else if (
            detectedCountryCode === "mx" ||
            detectedCountryCode === "ar" ||
            detectedCountryCode === "cl" ||
            detectedCountryCode === "co"
          ) {
            languageCode = "es"; // Spanish
          } else if (detectedCountryCode === "pt") {
            languageCode = "br"; // Portuguese
          } else if (
            detectedCountryCode === "at" ||
            detectedCountryCode === "ch"
          ) {
            languageCode = "de"; // German
          } else if (
            detectedCountryCode === "be" ||
            detectedCountryCode === "ch"
          ) {
            languageCode = "fr"; // French
          }

          // Update state with detected country code
          setCountryCode(detectedCountryCode);

          // Set the redirect message based on the language code
          setRedirectMessage(
            redirectMessages[languageCode] || redirectMessages["us"]
          );

          console.log("Final country code for redirect:", detectedCountryCode);
          console.log("Using language for messages:", languageCode);

          redirectToStore(detectedCountryCode);
        })
        .catch((err) => {
          console.error("Error fetching country from IP:", err);
          // Fallback to US store for safety
          setCountryCode("us");
          setRedirectMessage(redirectMessages["us"]);
          redirectToStore("us");
        });
    } catch (err) {
      console.error("Error during redirect:", err);
      setError(true);
      // Fallback to US store
      setCountryCode("us");
      setRedirectMessage(redirectMessages["us"]);
      redirectToStore("us");
    }
  }, []);

  // Helper function to redirect to the appropriate store
  const redirectToStore = (code) => {
    const appStoreUrl = `https://apps.apple.com/${code}/app/minipi-ai-baby-family-photo/id6741158174`;
    console.log(`Redirecting to: ${appStoreUrl}`);

    // Set a timeout to redirect after a short delay
    setTimeout(() => {
      // Pause and remove the video before redirecting
      const videoElement = document.querySelector(".background-video");
      if (videoElement) {
        // Pause the video
        videoElement.pause();

        // Remove the source to stop loading
        const sourceElement = videoElement.querySelector("source");
        if (sourceElement) {
          sourceElement.removeAttribute("src");
        }

        // Force the browser to stop loading the video
        videoElement.load();
      }

      // Now redirect to the App Store
      window.location.href = appStoreUrl;
    }, 2000); // Increased to 3 seconds to allow users to see the nice animation
  };

  // Get the appropriate error message object, fallback to US English if not found
  const getErrorMessage = () => {
    // Try to get the message for the detected country code
    let message = completeErrorMessages[countryCode];

    // If not found, fall back to US English
    if (!message) {
      message = completeErrorMessages["us"];
    }

    return message;
  };

  if (error) {
    const errorMessage = getErrorMessage();

    return (
      <div className="redirect-container">
        <video autoPlay muted loop className="background-video">
          <source src="https://un.ms//assets/pile/sky.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="redirect-content">
          <img src={minipi} alt="Minipi App" className="redirect-logo" />
          <div className="redirect-error">
            {errorMessage.before}
            <a
              href={`https://apps.apple.com/${countryCode}/app/minipi-ai-baby-family-photo/id6741158174`}
            >
              {errorMessage.link}
            </a>
            {errorMessage.after}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="redirect-container">
      <video autoPlay muted loop className="background-video">
        <source src="https://un.ms//assets/pile/sky.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="redirect-content">
        <img src={minipi} alt="Minipi App" className="redirect-logo" />
        <div className="redirect-text">{redirectMessage}</div>
        <div className="redirect-spinner">
          <div className="spinner-dot"></div>
          <div className="spinner-dot"></div>
          <div className="spinner-dot"></div>
        </div>
        <div className="redirect-app-store">
          <a
            href={`https://apps.apple.com/${countryCode}/app/minipi-ai-baby-family-photo/id6741158174`}
            className="app-store-button"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: "8px" }}
            >
              <path
                d="M17.0449 12.2915C17.0344 10.3228 18.1405 9.0415 20.3809 8.1368C19.2749 6.5868 17.6344 5.7728 15.5249 5.6608C13.5249 5.5488 11.3405 6.8301 10.6344 6.8301C9.87891 6.8301 7.93359 5.7168 6.37891 5.7168C3.37891 5.7728 0.15625 8.1928 0.15625 13.1228C0.15625 14.5128 0.378906 15.9588 0.824219 17.4608C1.44531 19.4608 3.92969 24.0001 6.52344 23.9441C7.87891 23.9161 8.82422 22.9555 10.6344 22.9555C12.3809 22.9555 13.2656 23.9441 14.7656 23.9441C17.3809 23.9161 19.6344 19.7915 20.2344 17.7915C16.7344 16.0448 17.0449 12.3695 17.0449 12.2915ZM14.0249 3.7541C15.8125 1.6448 15.6344 0.0948 15.5809 -0.000100136C14.3125 0.0668999 12.8125 0.8808 11.9249 1.8648C10.9249 2.9368 10.3809 4.2181 10.5249 5.6328C11.9249 5.7448 12.9249 5.0415 14.0249 3.7541Z"
                fill="#0066CC"
              />
            </svg>
            <span>Download on the App Store</span>
          </a>
        </div>
      </div>
    </div>
  );
}

// Diress redirect component
function DiressRedirect() {
  const [error, setError] = useState(false);
  const [countryCode, setCountryCode] = useState("us"); // Default to US store
  const [redirectMessage, setRedirectMessage] = useState(
    "Redirecting to App Store..."
  ); // Default to English

  // Localized redirect messages
  const redirectMessages = {
    tr: "App Store'a yönlendiriliyorsunuz",
    us: "Redirecting to App Store",
    uk: "Redirecting to App Store",
    de: "Weiterleitung zum App Store",
    fr: "Redirection vers l'App Store",
    es: "Redireccionando a la App Store",
    it: "Reindirizzamento all'App Store",
    jp: "App Storeにリダイレクトしています",
    kr: "앱 스토어로 리디렉션 중",
    cn: "正在重定向到App Store",
    ru: "Перенаправление в App Store",
    br: "Redirecionando para a App Store",
    // Add more languages as needed
  };

  // Complete localized error messages with click here text integrated
  const completeErrorMessages = {
    tr: {
      before: "Yönlendirme hatası. App Store'a gitmek için lütfen ",
      link: "buraya tıklayın",
      after: ".",
    },
    us: {
      before: "Redirect error. Please ",
      link: "click here",
      after: " to go to the App Store.",
    },
    uk: {
      before: "Redirect error. Please ",
      link: "click here",
      after: " to go to the App Store.",
    },
    de: {
      before: "Weiterleitungsfehler. Bitte ",
      link: "klicken Sie hier",
      after: ", um zum App Store zu gelangen.",
    },
    fr: {
      before: "Erreur de redirection. Veuillez ",
      link: "cliquer ici",
      after: " pour accéder à l'App Store.",
    },
    es: {
      before: "Error de redirección. Por favor ",
      link: "haga clic aquí",
      after: " para ir a la App Store.",
    },
    it: {
      before: "Errore di reindirizzamento. Si prega di ",
      link: "cliccare qui",
      after: " per andare all'App Store.",
    },
    jp: {
      before: "リダイレクトエラー。App Storeに行くには ",
      link: "ここをクリック",
      after: " してください。",
    },
    kr: {
      before: "리디렉션 오류. App Store로 이동하려면 ",
      link: "여기를 클릭",
      after: " 하십시오.",
    },
    cn: {
      before: "重定向错误。请 ",
      link: "点击这里",
      after: " 前往App Store。",
    },
    ru: {
      before: "Ошибка перенаправления. Пожалуйста, ",
      link: "нажмите здесь",
      after: ", чтобы перейти в App Store.",
    },
    br: {
      before: "Erro ao redirecionar. Por favor ",
      link: "clique aqui",
      after: " para ir para a App Store.",
    },
    // Add more languages as needed
  };

  useEffect(() => {
    // IMPORTANT: For this specific site, we want to prioritize Turkish users
    // Force redirect to Turkish store for certain conditions
    const forceRedirectToTurkishStore = () => {
      // Check if hostname contains .tr or is a known Turkish domain
      if (
        window.location.hostname.includes(".tr") ||
        window.location.hostname === "monailisa.com" ||
        window.location.hostname === "www.monailisa.com"
      ) {
        return true;
      }

      // Check if browser language is Turkish
      const userLanguage = navigator.language || navigator.userLanguage || "";
      if (userLanguage.startsWith("tr")) {
        return true;
      }

      return false;
    };

    // If we should force redirect to Turkish store, do it immediately
    if (forceRedirectToTurkishStore()) {
      console.log("Turkish user detected, redirecting to Turkish App Store");
      setCountryCode("tr");
      setRedirectMessage(redirectMessages["tr"] || redirectMessages["us"]);
      redirectToStore("tr");
      return;
    }

    try {
      // First try to get country from IP address
      fetch("https://ipapi.co/json/")
        .then((response) => response.json())
        .then((data) => {
          let detectedCountryCode = data.country_code?.toLowerCase() || "us";
          console.log("Country detected from IP:", detectedCountryCode);

          // Force Turkish store for Turkish IP addresses
          if (detectedCountryCode === "tr") {
            detectedCountryCode = "tr";
          }

          // Set the country code and appropriate message
          setCountryCode(detectedCountryCode);
          setRedirectMessage(
            redirectMessages[detectedCountryCode] || redirectMessages["us"]
          );

          // Get browser language for error messages
          const browserLanguage =
            (navigator.language || navigator.userLanguage || "")
              .split("-")[0]
              .toLowerCase() || "en";
          console.log("Browser language:", browserLanguage);

          // Map browser language to our supported language codes
          const languageMap = {
            en: "us", // English -> US store messages
            tr: "tr", // Turkish
            de: "de", // German
            fr: "fr", // French
            es: "es", // Spanish
            it: "it", // Italian
            ja: "jp", // Japanese
            ko: "kr", // Korean
            zh: "cn", // Chinese
            ru: "ru", // Russian
            pt: "br", // Portuguese -> Brazil store messages
          };

          // Get the language code to use for messages
          const languageCode =
            languageMap[browserLanguage] ||
            (detectedCountryCode in redirectMessages
              ? detectedCountryCode
              : "us");
          console.log("Using language for messages:", languageCode);

          redirectToStore(detectedCountryCode);
        })
        .catch((err) => {
          console.error("Error fetching country from IP:", err);
          // Fallback to US store for safety
          setCountryCode("us");
          setRedirectMessage(redirectMessages["us"]);
          redirectToStore("us");
        });
    } catch (err) {
      console.error("Error during redirect:", err);
      setError(true);
      // Fallback to US store
      setCountryCode("us");
      setRedirectMessage(redirectMessages["us"]);
      redirectToStore("us");
    }
  }, []);

  // Helper function to redirect to the appropriate store
  const redirectToStore = (code) => {
    const appStoreUrl = `https://apps.apple.com/${code}/app/diress-ai-modeli-giydir/id6738030797`;
    console.log(`Redirecting to: ${appStoreUrl}`);

    // Set a timeout to redirect after a short delay
    setTimeout(() => {
      // Pause and remove the video before redirecting
      const videoElement = document.querySelector(".background-video");
      if (videoElement) {
        // Pause the video
        videoElement.pause();

        // Remove the source to stop loading
        const sourceElement = videoElement.querySelector("source");
        if (sourceElement) {
          sourceElement.removeAttribute("src");
        }

        // Force the browser to stop loading the video
        videoElement.load();
      }

      // Now redirect to the App Store
      window.location.href = appStoreUrl;
    }, 3000); // 3 seconds to allow users to see the nice animation
  };

  // Get the appropriate error message object, fallback to US English if not found
  const getErrorMessage = () => {
    // Try to get the message for the detected country code
    const message =
      completeErrorMessages[countryCode] || completeErrorMessages["us"];
    return message;
  };

  // If there's an error, show an error message with a manual link
  if (error) {
    const errorMessage = getErrorMessage();
    const appStoreUrl = `https://apps.apple.com/${countryCode}/app/diress-ai-modeli-giydir/id6738030797`;

    return (
      <div className="redirect-container">
        <video autoPlay muted loop className="background-video">
          <source src="https://un.ms//assets/pile/sky.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="redirect-content">
          <img src={diress} alt="Diress App" className="redirect-logo" />
          <div className="redirect-error">
            {errorMessage.before}
            <a href={appStoreUrl}>{errorMessage.link}</a>
            {errorMessage.after}
          </div>
        </div>
      </div>
    );
  }

  // Show the redirect message
  return (
    <div className="redirect-container">
      <video autoPlay muted loop className="background-video">
        <source src="https://un.ms//assets/pile/sky.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="redirect-content">
        <img src={diress} alt="Diress App" className="redirect-logo" />
        <div className="redirect-text">{redirectMessage}</div>
        <div className="redirect-spinner">
          <div className="spinner-dot"></div>
          <div className="spinner-dot"></div>
          <div className="spinner-dot"></div>
        </div>
        <div className="redirect-app-store">
          <a
            href={`https://apps.apple.com/${countryCode}/app/diress-ai-modeli-giydir/id6738030797`}
            className="app-store-button"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: "8px" }}
            >
              <path
                d="M17.0449 12.2915C17.0344 10.3228 18.1405 9.0415 20.3809 8.1368C19.2749 6.5868 17.6344 5.7728 15.5249 5.6608C13.5249 5.5488 11.3405 6.8301 10.6344 6.8301C9.87891 6.8301 7.93359 5.7168 6.37891 5.7168C3.37891 5.7728 0.15625 8.1928 0.15625 13.1228C0.15625 14.5128 0.378906 15.9588 0.824219 17.4608C1.44531 19.4608 3.92969 24.0001 6.52344 23.9441C7.87891 23.9161 8.82422 22.9555 10.6344 22.9555C12.3809 22.9555 13.2656 23.9441 14.7656 23.9441C17.3809 23.9161 19.6344 19.7915 20.2344 17.7915C16.7344 16.0448 17.0449 12.3695 17.0449 12.2915ZM14.0249 3.7541C15.8125 1.6448 15.6344 0.0948 15.5809 -0.000100136C14.3125 0.0668999 12.8125 0.8808 11.9249 1.8648C10.9249 2.9368 10.3809 4.2181 10.5249 5.6328C11.9249 5.7448 12.9249 5.0415 14.0249 3.7541Z"
                fill="#0066CC"
              />
            </svg>
            <span>Download on the App Store</span>
          </a>
        </div>
      </div>
    </div>
  );
}

// CoupleShot redirect component
function CoupleShotRedirect() {
  const [error, setError] = useState(false);
  const [countryCode, setCountryCode] = useState("us"); // Default to US store
  const [redirectMessage, setRedirectMessage] = useState(
    "Redirecting to App Store..."
  ); // Default to English

  // Localized redirect messages
  const redirectMessages = {
    tr: "App Store'a yönlendiriliyorsunuz",
    us: "Redirecting to App Store",
    uk: "Redirecting to App Store",
    de: "Weiterleitung zum App Store",
    fr: "Redirection vers l'App Store",
    es: "Redireccionando a la App Store",
    it: "Reindirizzamento all'App Store",
    jp: "App Storeにリダイレクトしています",
    kr: "앱 스토어로 리디렉션 중",
    cn: "正在重定向到App Store",
    ru: "Перенаправление в App Store",
    br: "Redirecionando para a App Store",
    // Add more languages as needed
  };

  // Complete localized error messages with click here text integrated
  const completeErrorMessages = {
    tr: {
      before: "Yönlendirme hatası. App Store'a gitmek için lütfen ",
      link: "buraya tıklayın",
      after: ".",
    },
    us: {
      before: "Redirect error. Please ",
      link: "click here",
      after: " to go to the App Store.",
    },
    uk: {
      before: "Redirect error. Please ",
      link: "click here",
      after: " to go to the App Store.",
    },
    de: {
      before: "Weiterleitungsfehler. Bitte ",
      link: "klicken Sie hier",
      after: ", um zum App Store zu gelangen.",
    },
    fr: {
      before: "Erreur de redirection. Veuillez ",
      link: "cliquer ici",
      after: " pour accéder à l'App Store.",
    },
    es: {
      before: "Error de redirección. Por favor ",
      link: "haga clic aquí",
      after: " para ir a la App Store.",
    },
    it: {
      before: "Errore di reindirizzamento. Si prega di ",
      link: "cliccare qui",
      after: " per andare all'App Store.",
    },
    jp: {
      before: "リダイレクトエラー。App Storeに行くには ",
      link: "ここをクリック",
      after: " してください。",
    },
    kr: {
      before: "리디렉션 오류. App Store로 이동하려면 ",
      link: "여기를 클릭",
      after: " 하십시오.",
    },
    cn: {
      before: "重定向错误。请 ",
      link: "点击这里",
      after: " 前往App Store。",
    },
    ru: {
      before: "Ошибка перенаправления. Пожалуйста, ",
      link: "нажмите здесь",
      after: ", чтобы перейти в App Store.",
    },
    br: {
      before: "Erro ao redirecionar. Por favor ",
      link: "clique aqui",
      after: " para ir para a App Store.",
    },
    // Add more languages as needed
  };

  useEffect(() => {
    // IMPORTANT: For this specific site, we want to prioritize Turkish users
    // Force redirect to Turkish store for certain conditions
    const forceRedirectToTurkishStore = () => {
      // Check if hostname contains .tr or is a known Turkish domain
      if (
        window.location.hostname.includes(".tr") ||
        window.location.hostname === "monailisa.com" ||
        window.location.hostname === "www.monailisa.com"
      ) {
        return true;
      }

      // Check if browser language is Turkish
      const userLanguage = navigator.language || navigator.userLanguage || "";
      if (userLanguage.startsWith("tr")) {
        return true;
      }

      return false;
    };

    // If we should force redirect to Turkish store, do it immediately
    if (forceRedirectToTurkishStore()) {
      console.log("Turkish user detected, redirecting to Turkish App Store");
      setCountryCode("tr");
      setRedirectMessage(redirectMessages["tr"] || redirectMessages["us"]);
      redirectToStore("tr");
      return;
    }

    try {
      // First try to get country from IP address
      fetch("https://ipapi.co/json/")
        .then((response) => response.json())
        .then((data) => {
          let detectedCountryCode = data.country_code?.toLowerCase() || "us";
          console.log("Country detected from IP:", detectedCountryCode);

          // Force Turkish store for Turkish IP addresses
          if (detectedCountryCode === "tr") {
            detectedCountryCode = "tr";
          }

          // Set the country code and appropriate message
          setCountryCode(detectedCountryCode);
          setRedirectMessage(
            redirectMessages[detectedCountryCode] || redirectMessages["us"]
          );

          // Get browser language for error messages
          const browserLanguage =
            (navigator.language || navigator.userLanguage || "")
              .split("-")[0]
              .toLowerCase() || "en";
          console.log("Browser language:", browserLanguage);

          // Map browser language to our supported language codes
          const languageMap = {
            en: "us", // English -> US store messages
            tr: "tr", // Turkish
            de: "de", // German
            fr: "fr", // French
            es: "es", // Spanish
            it: "it", // Italian
            ja: "jp", // Japanese
            ko: "kr", // Korean
            zh: "cn", // Chinese
            ru: "ru", // Russian
            pt: "br", // Portuguese -> Brazil store messages
          };

          // Get the language code to use for messages
          const languageCode =
            languageMap[browserLanguage] ||
            (detectedCountryCode in redirectMessages
              ? detectedCountryCode
              : "us");
          console.log("Using language for messages:", languageCode);

          redirectToStore(detectedCountryCode);
        })
        .catch((err) => {
          console.error("Error fetching country from IP:", err);
          // Fallback to US store for safety
          setCountryCode("us");
          setRedirectMessage(redirectMessages["us"]);
          redirectToStore("us");
        });
    } catch (err) {
      console.error("Error during redirect:", err);
      setError(true);
      // Fallback to US store
      setCountryCode("us");
      setRedirectMessage(redirectMessages["us"]);
      redirectToStore("us");
    }
  }, []);

  // Helper function to redirect to the appropriate store
  const redirectToStore = (code) => {
    const appStoreUrl = `https://apps.apple.com/${code}/app/coupleshot-ai-photo-generator/id6740156638`;
    console.log(`Redirecting to: ${appStoreUrl}`);

    // Set a timeout to redirect after a short delay
    setTimeout(() => {
      // Pause and remove the video before redirecting
      const videoElement = document.querySelector(".background-video");
      if (videoElement) {
        // Pause the video
        videoElement.pause();

        // Remove the source to stop loading
        const sourceElement = videoElement.querySelector("source");
        if (sourceElement) {
          sourceElement.removeAttribute("src");
        }

        // Force the browser to stop loading the video
        videoElement.load();
      }

      // Now redirect to the App Store
      window.location.href = appStoreUrl;
    }, 3000); // 3 seconds to allow users to see the nice animation
  };

  // Get the appropriate error message object, fallback to US English if not found
  const getErrorMessage = () => {
    // Try to get the message for the detected country code
    const message =
      completeErrorMessages[countryCode] || completeErrorMessages["us"];
    return message;
  };

  // If there's an error, show an error message with a manual link
  if (error) {
    const errorMessage = getErrorMessage();
    const appStoreUrl = `https://apps.apple.com/${countryCode}/app/coupleshot-ai-photo-generator/id6740156638`;

    return (
      <div className="redirect-container">
        <video autoPlay muted loop className="background-video">
          <source src="https://un.ms//assets/pile/sky.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="redirect-content">
          <img
            src={coupleshot}
            alt="CoupleShot App"
            className="redirect-logo"
          />
          <div className="redirect-error">
            {errorMessage.before}
            <a href={appStoreUrl}>{errorMessage.link}</a>
            {errorMessage.after}
          </div>
        </div>
      </div>
    );
  }

  // Show the redirect message
  return (
    <div className="redirect-container">
      <video autoPlay muted loop className="background-video">
        <source src="https://un.ms//assets/pile/sky.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="redirect-content">
        <img src={coupleshot} alt="CoupleShot App" className="redirect-logo" />
        <div className="redirect-text">{redirectMessage}</div>
        <div className="redirect-spinner">
          <div className="spinner-dot"></div>
          <div className="spinner-dot"></div>
          <div className="spinner-dot"></div>
        </div>
        <div className="redirect-app-store">
          <a
            href={`https://apps.apple.com/${countryCode}/app/coupleshot-ai-photo-generator/id6740156638`}
            className="app-store-button"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: "8px" }}
            >
              <path
                d="M17.0449 12.2915C17.0344 10.3228 18.1405 9.0415 20.3809 8.1368C19.2749 6.5868 17.6344 5.7728 15.5249 5.6608C13.5249 5.5488 11.3405 6.8301 10.6344 6.8301C9.87891 6.8301 7.93359 5.7168 6.37891 5.7168C3.37891 5.7728 0.15625 8.1928 0.15625 13.1228C0.15625 14.5128 0.378906 15.9588 0.824219 17.4608C1.44531 19.4608 3.92969 24.0001 6.52344 23.9441C7.87891 23.9161 8.82422 22.9555 10.6344 22.9555C12.3809 22.9555 13.2656 23.9441 14.7656 23.9441C17.3809 23.9161 19.6344 19.7915 20.2344 17.7915C16.7344 16.0448 17.0449 12.3695 17.0449 12.2915ZM14.0249 3.7541C15.8125 1.6448 15.6344 0.0948 15.5809 -0.000100136C14.3125 0.0668999 12.8125 0.8808 11.9249 1.8648C10.9249 2.9368 10.3809 4.2181 10.5249 5.6328C11.9249 5.7448 12.9249 5.0415 14.0249 3.7541Z"
                fill="#0066CC"
              />
            </svg>
            <span>Download on the App Store</span>
          </a>
        </div>
      </div>
    </div>
  );
}

function MainContent({ currentYear }) {
  return (
    <div className="container">
      <video autoPlay muted loop playsInline className="background-video">
        <source src="https://un.ms//assets/pile/sky.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <h2 className="app-title">
        Our
        <br />
        Applications
      </h2>
      <header className="app-header">
        <div className="apps">
          <Link to="/coupleshot-app" className="app">
            <div className="badge live-badge">Live</div>
            <img src={coupleshot} alt="CoupleShot App" className="app-icon" />
            <p>CoupleShot</p>
          </Link>
          <Link to="/minipi-app" className="app">
            <div className="badge live-badge">Live</div>
            <img src={minipi} alt="Minipi" className="app-icon" />
            <p>Minipi</p>
          </Link>
          <div className="app">
            <div className="badge soon-badge">Soon</div>
            <img src={refinery} alt="Refinery" className="app-icon refinery" />
            <p>Refinery</p>
          </div>
          <Link to="/diress-app" className="app">
            <div className="badge live-badge">Live</div>
            <img src={diress} alt="Diress App" className="app-icon" />
            <p>Diress</p>
          </Link>
          <div className="app">
            <div className="badge soon-badge">Soon</div>
            <img src={simu} alt="Simu" className="app-icon" />
            <p>Simu AI</p>
          </div>
          <div className="app">
            <div className="badge live-badge">Live</div>
            <img src={producter} alt="Producter" className="app-icon" />
            <p>Producter</p>
          </div>
          <div className="app">
            <div className="badge soon-badge">Soon</div>
            <img src={modipic} alt="Modipic" className="app-icon" />
            <p>Drobify</p>
          </div>
          <div className="app">
            <div className="badge soon-badge">Soon</div>
            <img src={separai} alt="Separai" className="app-icon" />
            <p>Separai</p>
          </div>
        </div>
      </header>

      <img src={logo} alt="Logo" className="bottom-logo" />

      <footer>
        <p>
          © {currentYear} Monailisa. All rights reserved |{" "}
          <Link to="/contact" className="contact-link">
            Contact
          </Link>
        </p>
      </footer>
    </div>
  );
}

function App() {
  const [loading, setLoading] = useState(true);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const handleLoad = () => setLoading(false);
    if (document.readyState === "complete") handleLoad();
    else window.addEventListener("load", handleLoad);
    return () => window.removeEventListener("load", handleLoad);
  }, []);

  if (loading) {
    return (
      <div className="loading-screen">
        <div className="loading-text">
          Loading assets<span className="dot">.</span>
          <span className="dot">.</span>
          <span className="dot">.</span>
        </div>
      </div>
    );
  }

  return (
    <Router>
      <Routes>
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/minipi-app" element={<MinipiRedirect />} />
        <Route path="/diress-app" element={<DiressRedirect />} />
        <Route path="/coupleshot-app" element={<CoupleShotRedirect />} />
        <Route path="/" element={<MainContent currentYear={currentYear} />} />
      </Routes>
    </Router>
  );
}

export default App;
